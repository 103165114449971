<template>
    <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ title }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">
                    <div class="row py-1 mx-auto">
                        <div class="col-12">
                            <label class="form-label" for="expiredDate">Expired Date</label>
                            <input
                                placeholder="Please select expired date"
                                name="expiredDate"
                                type="date"
                                v-model.trim="expiredDate"
                                class="form-control"
                            />
                        </div>
                        <div class="col-12 text-center">
                            <SubmitCancelBtn
                                class="text-center"
                                @onSubmit="submit"
                                @onCancel="onClickClose"
                                :loading="loading"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import handleHospital from '@/services/modules/hospital'
import { inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';

const props = defineProps({
    title: {
        type: String,
        default: 'Edit Expired Date'
    },
    stock: {
        type: Object || null,
        default: null
    },
    onUpdateExpiredDate: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onUpdateExpiredDate']);

const { updateExpiredDate } = handleHospital();
const store = useStore();
const route = useRoute();
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const expiredDate = ref(null);

const submit = async() => {

    if(! expiredDate.value) {
        return showError('Please select expired date.')
    }

    const formData = {
        company_id: route.params.companyId,
        exp_date: expiredDate.value
    }

    loading.value = true;

    await updateExpiredDate(formData, props.stock.id).then( async (res) => {
        
        if (!res.status) return showError(res.message);

        emit('onUpdateExpiredDate', expiredDate.value);
        onClickClose();
        showSuccess(res.message)
        
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const onClickClose = () => {
    store.state.isModalOpenThree = false;
}

onMounted(() => {
    if(props.stock && props.stock.exp_date) {
        expiredDate.value = props.stock.exp_date
    }
})
</script>

<style scoped>
.modal-style {
    display: block; 
    background: #1c1d1c33
}
.roles-segment {
    margin-top: -13px;
}
</style>
