<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table visit-report-table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Supplier Name</th>
                        <th>Batch No</th>
                        <th>Purchase Date</th>
                        <th>Stock in hand</th>
                        <th>Exp Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.supplier_name }}</td>
                        <td>{{ item.batch_number }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.stock_in_hand }}</td>
                        <td>
                            <a href="javascript:void(0)" @click="onClickToEdit(item)">
                                <span class="me-1">{{ item.exp_date }}</span>
                                <span><i class="fa fa-edit"></i></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p v-if="!tableItems.length" class="text-center mt-5">No data</p>

            <EditExpiredDateModal 
                v-if="store.state.isModalOpenThree"
                :stock="stock"
                @onUpdateExpiredDate="onUpdateExpiredDate"
            />
        </div>
    </div>
</template>

<script setup>
import EditExpiredDateModal from '@/components/molecule/company/hospital/pharmacy/EditExpiredDateModal.vue';
import { ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';

const props = defineProps({
    tableItems: {
        type: Array,
    },
    reloadTable: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['reloadTable']);

const route = useRoute();
const store = useStore();
const stock = ref(null);

const onClickToEdit = (item) => {
    stock.value = item;
    store.state.isModalOpenThree = true
}

const onUpdateExpiredDate = (expiredDate) => {
    emit('reloadTable');
}
</script>

<style scoped>
.visit-report-table > :not(caption) > * > * {
    padding: 1rem !important;
}

.visit-report-table .pl-10px {
    padding-left: 10px !important;
}
</style>