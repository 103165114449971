<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
                title="Expired Product List"
                btnTitle="Add new"
                :showBtn="false"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
                :isHideDate="true"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Manufacturer</label>
                    <v-select
                        placeholder="Select manufacturer"
                        label="name"
                        :options="manufacturers"
                        v-model="manufacturer"
                        :reduce="text => text.value"
                    />
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Supplier</label>
                    <AsyncSelect
                        placeholder="Search Supplier by Name, ID, Mobile No"
                        v-model="supplier"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ type: 'supplier'}"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Products</label>
                    <AsyncSelect
                        placeholder="Select product"
                        v-model="product"
                        :api-service="fetchProducts"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 d-flex justify-content-end px-2 gap-1">
            <button @click="exportTable" class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect">Export</button>
            <button @click="goToPrint" :disabled="loading" class="btn btn-primary btn-block waves-effect waves-float waves-light">Print</button>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
                @reloadTable="getExpiredProducts"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/hospital/pharmacy/ExpiredProductTable.vue';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHospital from '@/services/modules/hospital'
import handlePurchase from '@/services/modules/purchase';
import handleContact from '@/services/modules/contact'
import pdfPrinter from '@/services/utils/pdf/expiredProductReport';
import handleCompany from "@/services/modules/company";
import { computed } from 'vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const manufacturers = ref([]);
const manufacturer = ref(null);
const product = ref(null);
const supplier = ref(null);
const allProducts = ref([]);
const companyInfo = ref({});
const params = reactive({
    searchTxt: '',
    offset: 10
});

const { fetchExpiredProductReport, fetchManufacturer } = handleHospital();
const { fetchProducts } = handlePurchase();
const { fetchContactProfiles } = handleContact();
const { exportToPDF } = pdfPrinter();
const { fetchCompanyInfo } = handleCompany();

const token = computed(() => localStorage.getItem('token'));

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getExpiredProducts();
}

const onClickGo = () => {
    getExpiredProducts()
}

const exportTable = () => {

    let query = getQuery();
    query += '&is_export=1';
    query += '&_token=' + token.value;

    let url = `${process.env.VUE_APP_BASE_URL}/export/hospital/pharmacy/expired-products-reports` + query;
    let a = document.createElement("a")
    a.setAttribute("href", url)
    a.click()
}

const goToPrint = async () => {
    allProducts.value = [];
    const filters = getFilters();
    const isShowAll = true;

    await getExpiredProducts(isShowAll).then(() => {
        if(!allProducts.value.length) return;
        exportToPDF(companyInfo.value, allProducts.value, filters);
    })
}

const getFilters = () => {
    const data = {
        manufacturer: '',
        supplier: supplier.value ? supplier.value.name : '',
        product: product.value ? product.value.name : '',
    }

    if(manufacturer.value) {
        const item = manufacturers.value.find(m => m.value === manufacturer.value);
        data.manufacturer = item ? item.name : '';
    }

    return data;
}

const getExpiredProducts = async (isShowAll = false) => {
    let query = getQuery();
    if(isShowAll) query += '&is_show_all=1';

    loading.value = true

    await fetchExpiredProductReport(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        if(isShowAll){
            allProducts.value = res.data;
            return;
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getManufacturers = async () => {
    await fetchManufacturer(getQuery()).then(res => {
        if (!res.status) return
        manufacturers.value = res.data
    }).catch(err => {
        console.log(err)
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    if(manufacturer.value) query += `&manufacturer=${manufacturer.value}`;
    if(product.value) query += `&product_id=${product.value.id}`;
    if(supplier.value) query += `&supplier_id=${supplier.value.id}`;
    query += '&offset=' + params.offset
    return query;
}

const getCompany = async () => {
    await fetchCompanyInfo(route.params.companyId).then(res => {
        if (!res.status) return
        companyInfo.value = res.data
    }).catch(err => {
        console.log(err)
    })
}

onMounted(async () => {
    await Promise.all([
        getManufacturers(),
        getExpiredProducts(),
        getCompany()
    ]);
})
</script>

<style scoped>
.mr-top-1 {
    margin-top: 1% !important;
}
</style>